<template>
  <div class="h100">
    <el-card class="box-card">
      <avue-crud v-bind="bindVal"
                  v-on="onEvent"
                  :page.sync="page"
                  :search.sync="search"
                  :before-open="beforeOpen"
                  @on-load="getList"
                  @row-save="rowSave"
                  @row-update="rowUpdate"
                  @row-del="rowDel"
                  @size-change="sizeChange"
                  @current-change="currentChange"
                  v-model="form">
          <template slot="tempForm"
                    slot-scope="{}">
            <avue-crud @row-save="rowSave1"
                      @row-del="rowDel1"
                      :option="option1"
                      :data="data1"></avue-crud>
          </template>
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import { getTemp, addTemp, delTemp,list,add,update,del} from '@/api/zhdt/user'
import { url } from '@/config'
let orgId
export default window.$crudCommon({
  data () {
    return {
      option1: {
        editBtn: false,
        viewBtn: true,
        column: [{
          label: '条件执行',
          width: 150,
          prop: 'caseIds',
          type: 'table',
          span: 24,
          children: {
            border: true,
            column: [{
              label: '组织架构',
              prop: 'orgId',
              type: 'select',
              search: true,
              dicUrl: url + '/org-manage/list?size=100',
              dicFormatter: (data) => {
                return data.records
              },
              props: {
                label: 'name',
                value: 'id'
              },
            }, {
              label: '编号',
              prop: 'ifcondition'
            }, {
              label: '模版',
              prop: 'implement',
              type: 'textarea',
              span: 24
            }],
          },
          props: {
            label: 'ifcondition',
            value: 'id'
          },
          formatter: (row) => {
            if (!row.orgName) return ''
            return row.orgName + '-' + row.ifcondition
          },
          onLoad: ({ page, value, data = {} }, callback) => {
            //首次加载去查询对应的值
            if (value) {
              return
            }
            if (page) {
              if (data.orgId) {
                orgId = data.orgId
              }

              this.$axios.get(url + '/casecommon/caseList', {
                params: {
                  orgId: orgId,
                  currentPage: page.currentPage,
                  page: page.currentPage,
                  size: page.pageSize,
                  moduleId: 3
                }
              }).then(res => {
                const data = res.data;
                callback({
                  total: data.total,
                  data: data.records
                })
              })
            }
          }

        }, {
          label: '编号',
          prop: 'ifcondition',
          span: 24,
          addDisplay: false
        }, {
          label: '模板消息',
          prop: 'implement',
          type: 'textarea',
          span: 24,
          addDisplay: false
        },]
      },
      data1: [],
      duplicateSubmit:false,
      duplicateSave:false,
      duplicateUpdate:false,
    }
  },
  created () {
    this.duplicateSubmit = true;
    this.getList({currentPage:1,pageSize:10});
  },
  methods: {
    rowSave(row,done,loading){
      if(this.duplicateSave)return;

      this.duplicateSave = true
      add(row).then(() => {
        this.$message.success('新增成功')
        this.duplicateSubmit = true;
        this.getList(Object.assign({},this.search,{currentPage:1,pageSize:10}));
        done()
        this.duplicateSave = false
      }).catch(() => {
        loading()
      })
    },
    rowUpdate(row,index,done,loading){
      if(this.duplicateUpdate)return;

      this.duplicateUpdate = true
      update(row.id,row).then(() => {
        this.$message.success('更新成功')
        this.duplicateSubmit = true;
        this.getList(Object.assign({},this.search,{currentPage:1,pageSize:10}));
        done()
        this.duplicateUpdate = false
      }).catch(() => {
        loading()
      })
    },
    rowDel(row,index){
      this.$confirm('此操作将永久删除序号【'+ index +'】的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(row.id).then(() => {
          this.$message.success('删除成功')
          this.duplicateSubmit = true;
          this.getList(Object.assign({},this.search,{currentPage:1,pageSize:10}));
        })
      }).catch(() => {
      });
    },
    sizeChange(val){
      this.page.currentPage = 1
      this.page.pageSize = val
      this.search.pageSize = val;
      this.search.currentPage = 1;
      this.duplicateSubmit = true;
      this.getList(this.search);
    },
    currentChange(val) {
      this.page.currentPage = val
      this.search.currentPage = val;
      this.duplicateSubmit = true;
      this.getList(this.search);
    },
    searchChange(form,done){
    
      this.search = form
      this.search.pageSize = this.page.pageSize;
      this.search.currentPage = 1;
      this.page.currentPage = 1;
      list(this.search).then(res=>{
        let data = res.data.records;
        this.data = data;
        this.page.total = res.data.total;
        done()
      })
    },
    getList(data){
      if(!this.duplicateSubmit){
        return
      }
      let dataObj = Object.assign({},data,this.search);
      list(dataObj).then(res=>{
        let data = res.data.records;
        this.data = data;
        this.page.total = res.data.total;
        this.duplicateSubmit = false;
      })
    },
    rowSave1 (row, done) {
      row.userId = this.form.id
      addTemp(row).then(() => {
        this.getTemp();
        done()
      })
    },
    rowDel1 (row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTemp(row.id, this.form.id).then(() => {
          this.getTemp();
        })
      }).catch(() => {
      });
    },
    beforeOpen (done, type) {
      if (type == 'edit') {
        this.getTemp()
        this.form.orgId = Number(this.form.orgId)
      }
      done()
    },
    getTemp () {
      getTemp(this.form.id).then(res => {
        const data = res.data;
        this.data1 = data;
      })
    }
  }
}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/zhdt/user',
  res: (data) => {
    return {
      total: data.total,
      data: data.records
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="">
    <el-dialog width="80%"
               class="fileBox"
               :close-on-click-modal="false"
               :visible.sync="imgVisible">
      <list ref="formList"
            :isOption="true"
            :activeID="activeID"
            :orgId="orgId"
            @change="addgetActive"></list>
    </el-dialog>
  </div>
</template>

<script>
import list from "@/page/index";
export default {
  props: {
    option: Object,
    props: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  name: "index",
  components: {
    list,
  },
  data () {
    return {
      activeName: 0,
      orgId: '',
      activeID: [],
      imgVisible: false,
    };
  },
  methods: {
    addgetActive (item) {
      this.$emit("change", item);
      this.imgVisible = false;
    },
    //打开图库
    handleOpenlargeDataScreen (item, type) {
      this.imgVisible = true;
      this.activeID = item.frameId ? item.frameId.split(',') : []
      this.orgId = item.orgId
    },
  },
}
</script>
<style>
.fileBox .el-dialog {
  background: #000;
}
</style>
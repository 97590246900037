<template>
  <div class="h100">

    <el-card class="box-card">
      <largeDataScreen ref="largeDataScreen"
                       @change="addgetActive"></largeDataScreen>
      <avue-crud v-bind="bindVal"
                 v-on="onEvent"
                 :page.sync="page"
                 :search.sync="search"
                 v-model="form">
        <template slot="frameIdForm"
                  slot-scope="scope">
          <div style="display:flex;">
            <avue-input :rules="scope.rules"
                        placeholder="请输入大屏ID"
                        v-model="form.frameId"></avue-input>
            <el-button style="margin-left:10px"
                       type="primary"
                       icon="el-icon-plus"
                       size="small"
                       @click.stop="handleOpenlargeDataScreen">选择</el-button>
          </div>
        </template>
        <span slot="frameId"
              slot-scope="{row}"
              style="color:red"
              @click="goIframe(row)">
          {{row.frameId}}
        </span>
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import largeDataScreen from "./model/largeDataScreen";
export default window.$crudCommon({
  components: {
    largeDataScreen
  },
  methods: {
    addgetActive (res) {
      this.form.frameId = this.form.frameId + res.join(',');
    },
    goIframe (item) {
      this.$router({ path: '/build' + item.frameId })
    },
    handleOpenlargeDataScreen (item, type) {
      if (!this.form.orgId) {
        this.$message.error('请选择组织架构');
        return false;
      }
      this.$refs.largeDataScreen.handleOpenlargeDataScreen(this.form, type);
    },
  }
}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/zhdt/conditions',
  res: (data) => {
    return {
      total: data.total,
      data: data.records
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
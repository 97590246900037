import { render, staticRenderFns } from "./img copy.vue?vue&type=template&id=b94ad220&scoped=true&"
import script from "./img copy.vue?vue&type=script&lang=js&"
export * from "./img copy.vue?vue&type=script&lang=js&"
import style0 from "./img copy.vue?vue&type=style&index=0&lang=css&"
import style1 from "./img copy.vue?vue&type=style&index=1&id=b94ad220&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b94ad220",
  null
  
)

export default component.exports
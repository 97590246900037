<template>
  <div class="h100">
    <el-card class="box-card">
      <el-row class="mb20">
        <el-button type="primary" @click="dialogVisible = true;form={};type='新增'">新增</el-button>
        <el-button type="success" @click="menuUpdataAllByService">一键同步在线服务接口</el-button>
      </el-row>
      <el-table :data="tableData" border style="width: 100%" size="mini">
        <el-table-column prop="name" label="名称">
        </el-table-column>
        <el-table-column prop="path" label="路径" width="250">
        </el-table-column>
        <!-- <el-table-column prop="typeStr" label="下级" width="100">
            <template slot-scope="scope">
                <el-button @click="addchild(scope.row)" type="text" size="small"
              >添加下级</el-button>
            </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-popconfirm
  title="这是一段内容确定删除吗？"
  @confirm="handleDel(scope.row)"
>
   <el-button slot="reference" type="text" class="ml10" size="small">删除</el-button>
</el-popconfirm>
           
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tablePage"> -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 200, 300, 400]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
      <!-- </div> -->
        </el-card>
    <el-dialog
      :title="type"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="名称" prop="name" >
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item> 
        <el-form-item label="数据路径" prop="path">
          <el-autocomplete
      class="inline-input autocompletepath"
      v-model="form.path"
      :fetch-suggestions="querySearch"
      placeholder="请输入内容"
      @select="handleSelect"
    >
    <template slot-scope="{ item }">
      <div class="name">
        <span class="left">{{ item.value }}</span>
        <span class="right">{{ item.method }}</span>
      </div>
    </template>
    </el-autocomplete>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')"
          >确 定</el-button
        >
      </span>


    </el-dialog>


    <!-- 角色图 -->
    <el-dialog
      title="角色图"
      :visible.sync="showRoleTuVisible"
      width="80%"
    >
    
    <el-tree
  :data="RoleTree"
  node-key="id"
  default-expand-all
  draggable

  >
</el-tree>
    
    </el-dialog>


  </div>
</template>

<script>
import {getAllUrl,getMenuList,menuAdd,menuUpdate,menuDel,menuUpdataAllByService} from "@/api/auth";
export default {
  data() {
    return {
      state1:'',
      allUrl:[],
      type:"新增",
      form:{
        code:''
      },
      rules: {
          name: [
            { required: true, message: '请输入改数据路径名称', trigger: 'blur' },
          ],
          path: [
            { required: true, message: '请输入或选择数据路径 支持antPath模式', trigger: 'blur' },
          ]
          },
      page: {
        total: 0,
        currentPage: 1,
        page:1,
        size:10
      },
      dialogVisible: false,
      showRoleTuVisible:false,
      showParent:false,
      tableData: [],
      RoleTree:[],
      addParentRow:{},
      showChild:false,
      addChildRow:{}
    };
  },
  mounted() {
    this.getList();
    getAllUrl().then(res=>{
      this.allUrl=res.data
    })
  },
  methods: {
    menuUpdataAllByService(){
      menuUpdataAllByService().then(res=>{
        this.getList();
      })
    },
    querySearch(queryString, cb) {
        var restaurants = this.allUrl;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.indexOf(queryString) === 0);
        };
      },
      handleSelect(item) {
        console.log(item);
      },
      closeParentEvent(){
          this.addParentRow={};
          this.parentids=[];
      },
      addparentSure(){
          roleAddParent({
              id:this.addParentRow.id,
              pidstr:this.parentids.join(',')
          }).then(()=>{
              this.showParent=false;
          })
      },
      addParent(row){
          this.addParentRow=row;
          this.showParent=true;
          getAllRoleById({id:row.id}).then(res=>{
              this.parentidsAll=res.data.all;
              this.parentids=res.data.parentids;
          })
      },
      addchild(row){
          this.addChildRow=row;
          this.showChild=true;
          getAllRoleById({id:row.id}).then(res=>{
              this.parentidsAll=res.data.all;
              this.childids=res.data.childs;
          })
      },
      closeChildEvent(){
          this.addParentRow={};
          this.parentids=[];
      },
      addChildSure(){
          roleAddChild({
              id:this.addParentRow.id,
              cids:this.childids
          })
      },
      addChild(row){
          this.addChildRow=row;
          this.showChild=true;
          getAllRoleById({id:row.id}).then(res=>{
              this.parentidsAll=res.data.all;
          })
      },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let method=menuAdd;
            if(this.type=="编辑"){
                method=menuUpdate;
            }
            method(this.form).then(res=>{
              if(res.code==200){
                this.dialogVisible=false;
                this.form={};
                this.getList();
              }
              this.type = "新增";
            }).catch(e=>{
              
            })
            // alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    handleEdit(row) {
      this.type = "编辑";
      this.form = row;
      this.dialogVisible=true;
    },
    showRoleTu(row){
        getRoleTree().then(res=>{
            this.RoleTree=res.data;
        })
        this.showRoleTuVisible=true;
    },
    handleDel(row) {
      menuDel(row.id).then(res=>{
        this.getList();
      })
    },
    getList({page,size}=this.page) {
      getMenuList({page,size}).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
      });
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
  },
};
</script>

<style>
.autocompletepath{
  width: 100%;
}
.left{
  float: left;
}
.right{
  float:right;
}
</style>
<template>
  <div>
      <!-- :auto-upload="false" -->
        <!-- :http-request="uploadFile" -->
<el-upload
  class="upload-demo"
  drag
  ref="upload"
  accept=".zip"
  :on-success="success"
  with-credentials
  :data="{type:'screen'}"
  :limit="1"
  :action="base_url+'/zhdt/uploadFile'"
  multiple>
  <i class="el-icon-upload"></i>
  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>后点击上传</div>
  <div class="el-upload__tip" slot="tip">
    <p>只能上传.zip文件，且不超过100MB</p>
    </div>
</el-upload>
  </div>
</template>

<script>
import {base_url} from "@/utils/request"
export default {
  data(){
    return{
      base_url:base_url,
      isSucess:false,
    }
  },
mounted(){
},
    methods:{
      success(res,file,list){
        const win = window.open(base_url+"downLoad?name="+res.data,"下载");
        win.onload=function(){
            win.close();
        }
      },
    }

}
</script>

<style>



</style>
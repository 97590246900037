<template>
  <div class="h100">
    <el-card class="box-card h100">
      <div slot="header"
           class="clearfix">
        <el-steps :active="active">
          <el-step title="步骤 1"
                   :status="status['1']"
                   @click.native="click(1)"
                   description="上传视频标注文档，并筛选解析个视频数据"></el-step>
          <el-step title="步骤 2"
                   :status="status['2']"
                   @click.native="click(2)"
                   description="绘制视频帧数据"></el-step>
          <el-step title="步骤 3"
                   :status="status['3']"
                   @click.native="click(3)"
                   description="生成视频"></el-step>
        </el-steps>
      </div>
      <article v-show="active == 1">
        <el-button class="mb50"
                   size="small"
                   type="primary"
                   @click="isPass(1)">使用上次历史结果</el-button>
        <el-upload class="upload-demo"
                   :action="action"
                   :on-success="uploadSuccess"
                   :on-error="uploadError"
                   :limit="1"
                   accept=".csv">
          <el-button size="small"
                     type="primary">点击上传</el-button>
          <div slot="tip"
               class="el-upload__tip">
            上传视频标注csv文档，且去掉行标题
          </div>
        </el-upload>
      </article>
      <article v-show="active == 2">
        <el-button class=""
                   size="small"
                   type="primary"
                   @click="isPass(2)">使用上次历史结果</el-button>
        <el-row v-for="(index,key) in imageLoad"
                :key="key">
          <el-button type="primary"
                     :loading="imageLoad[key]==1"
                     @click="drawImage(key)">绘制{{key}}视频帧数据</el-button>
          <span class="imageText success"
                v-if="imageLoad[key]==2">帧数据处理成功</span>
          <span class="imageText ing"
                v-if="imageLoad[key]==1">处理中。。。</span>
          <span class="imageText error"
                v-if="imageLoad[key]==3">帧数据处理失败</span>
        </el-row>
        <el-row>
          <el-button type="primary"
                     @click="drawImageAll()">绘制全部视频帧数据</el-button>
        </el-row>
      </article>
      <article v-show="active == 3">
        <el-row v-for="(index,key) in videoLoad"
                :key="key">
          <el-button type="primary"
                     :loading="videoLoad[key]==1"
                     @click="image2Avi(key)">合成{{key}}视频mp4</el-button>
          <span class="imageText success"
                v-if="videoLoad[key]==2">视频处理成功</span>
          <span class="imageText ing"
                v-if="videoLoad[key]==1">处理中。。。</span>
          <span class="imageText error"
                v-if="videoLoad[key]==3">视频处理失败</span>
          <el-link style="margin-left:30px;"
                   type="primary"
                   @click="openVideo(key)">查看mp4</el-link>
          <el-dropdown style="margin-left:30px;">
            <span class="el-dropdown-link">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toavi(key)">合成avi并下载</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-row>
        <el-row>
          <el-button type="primary"
                     @click="image2AviAll()">绘制全部视频帧数据</el-button>
        </el-row>
      </article>
      <el-dialog title="视频预览"
                 :destroy-on-close="true"
                 :visible="dialogVisible"
                 width="700px"
                 :before-close="handleClose">
        <div style="text-align:center;font-size:20px;color:red">{{currentVideo}}.mp4</div>
        <video width="640"
               height="360"
               :src="getBaseUrl+'/video/getVideo?fileName='+currentVideo+'.mp4'"
               ref="video"
               controls="controls"
               preload="load">
          <!-- <source    type="video/mp4"> -->
          <!-- <source  :src="blobURL"   type="video/mp4"> -->
        </video>
      </el-dialog>
    </el-card>

  </div>
</template>
<script>
import { drawImage, image2Avi, loadAvi } from "@/api/video"
export default {
  name: "Video",
  computed: {
    getBaseUrl () {
      return false ? process.env.VUE_APP_BASE_API : "http://219.148.186.237:1990";
    }
  },
  data () {
    return {
      active: 1,
      action: process.env.VUE_APP_BASE_API + "/file/uploadCsv",
      fileList: [],
      dialogVisible: false,
      status: {
        1: "process",
        2: "process",
        3: "process",
      },
      currentVideo: "",
      blobURL: "",
      videoLoad: {
        "01_img": 0,
        "03_img": 0,
        "04_img": 0,
        "05_img": 0,
        "10_img": 0,
        "11_img": 0,
      },
      imageLoad: {
        "01_img": 0,
        "03_img": 0,
        "04_img": 0,
        "05_img": 0,
        "10_img": 0,
        "11_img": 0,
      },
      objLoad: {}
    };
  },
  methods: {
    toavi (fileName) {
      window.open(process.env.VUE_APP_BASE_API + 'video/toAvi?fileName=' + fileName);
    },
    openVideo (fileName) {
      this.dialogVisible = true;
      this.currentVideo = fileName
    },
    image2AviAll () {
      for (let i in this.videoLoad) {
        this.image2Avi(i);
      }
    },
    drawImageAll () {
      for (let i in this.imageLoad) {
        this.drawImage(i);
      }
    },
    handleClose () {
      this.$refs.video.src = ""
      this.dialogVisible = false;
    },
    isPass (arg) {
      this.active = arg + 1;
      this.status[arg] = "success"
    },
    image2Avi (fileName, format = "mp4") {
      // console.log(format)
      // if(format!='mp4'){
      //   this.objLoad[format]={};
      //   this.objLoad[format]['status']=
      // }
      this.videoLoad[fileName] = 1;
      image2Avi({ fileName: fileName }).then(res => {
        if (res.code == 200) {
          this.videoLoad[fileName] = 2;
          this.$set(this.status, 3, "success");
        } else {
          this.$set(this.status, 2, "error");
          this.videoLoad[fileName] = 3;
        }
      }).catch((e) => {
        this.$set(this.status, 3, "error");
        this.videoLoad[fileName] = 3;
      })
    },
    drawImage (fileName) {
      this.imageLoad[fileName] = 1;
      drawImage({ fileName: fileName }).then(res => {
        if (res.code == 200) {
          this.imageLoad[fileName] = 2;
          this.$set(this.status, 2, "success");
        } else {
          this.$set(this.status, 2, "error");
          this.imageLoad[fileName] = 3;
        }
      }).catch((e) => {
        this.$set(this.status, 2, "error");
        this.imageLoad[fileName] = 3;
      })
    },
    uploadSuccess () {
      this.$set(this.status, 1, "success");
      this.active = 2;
    },
    uploadError () {
      this.$set(this.status, 1, "error");
    },
    click (arg) {
      if (arg > 1 && this.status[arg - 1] == "success") {
        this.active = arg;
      }

      if (arg >= this.active) {
        return;
      }
      this.active = arg;
    },
  },
};
</script>
<style  lang="scss" >
.el-step__main {
  display: inline-block;
  width: 150px;
  cursor: pointer;
}
.el-step__main:hover {
  .el-step__description,
  .el-step__title {
    color: rgb(112, 112, 133);
  }
}
.el-row {
  margin-top: 20px;
}
</style>
<style lang="scss" scoped>
.mb50 {
  margin-bottom: 50px;
}
.imageText {
  font-size: 14px;
  padding-left: 20px;
}
.success {
  color: #409eff;
}
.error {
  color: #ff2050;
}
</style>

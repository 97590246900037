<template>
  <div class="h100">
    <el-card class="box-card">
      <el-row class="mb20">
        <el-button type="primary"
                   @click="
            dialogVisible = true;
            form = {};
            type = '新增';
          ">新增</el-button>
      </el-row>
      <el-table :data="tableData"
                border
                style="width: 100%"
                size="mini">
        <el-table-column prop="userName"
                         label="名称"
                         width="100">
        </el-table-column>
        <el-table-column prop="phone"
                         label="联系电话"
                         width="100">
        </el-table-column>
        <el-table-column prop="orgName"
                         label="所属组织"
                         width="100">
        </el-table-column>
        <!-- <el-table-column prop="typeStr" label="用户类型" width="100">
        </el-table-column> -->
        <el-table-column prop="createTime"
                         label="创建时间"> </el-table-column>
        <el-table-column prop="updateTime"
                         label="更新时间"> </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="150">
          <template slot-scope="scope">
            <el-button @click="showRoleTu(scope.row)"
                       type="text"
                       size="small">绑定角色</el-button>
            <el-button @click="resetPassword(scope.row)"
                       type="text"
                       size="small">重置密码</el-button>
            <el-button @click="handleEdit(scope.row)"
                       type="text"
                       size="small">编辑</el-button>
            <el-popconfirm title="这是一段内容确定删除吗？"
                           @confirm="handleDel(scope.row)">
              <el-button slot="reference"
                         type="text"
                         class="ml10"
                         size="small">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tablePage"> -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="page.currentPage"
                     :page-sizes="[10, 200, 300, 400]"
                     :page-size="page.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="page.total">
      </el-pagination>
      <!-- </div> -->
    </el-card>
    <el-dialog :title="type"
               :visible.sync="dialogVisible"
               width="80%">
      <el-form ref="form"
               :model="form"
               :rules="rules"
               label-width="100px">
        <el-form-item label="名称"
                      prop="userName">
          <el-input v-model="form.userName"
                    placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="联系电话"
                      prop="phone">
          <el-input v-model="form.phone"
                    placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="组织架构"
                      prop="orgId">
          <el-select v-model="form.orgId"
                     clearable
                     placeholder="请选择组织">
            <template v-for="(item, i) in orgs">
              <el-option :value="item.id"
                         :label="item.name"
                         :key="i"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="用户类型" prop="orgId">
          <el-select
            v-model="form.status"
            clearable
            placeholder="请选择用户类型"
          >
            <template v-for="(item, i) in userState">
              <el-option
                :value="item.id"
                :label="item.name"
                :key="i"
              ></el-option>
            </template>
          </el-select>
        </el-form-item> -->
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submitForm('form')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 角色图 -->
    <el-dialog title="角色图"
               ref="treeDialog"
               :visible.sync="showRoleTuVisible"
               width="50%">
      <!-- <el-tree
        check-strictly
        :data="RoleTree"
        node-key="id"
        ref="tree"
        :default-expanded-keys="defaultCheckKeys"
        show-checkbox
        default-expand-all
      >
      </el-tree> -->
      <el-checkbox :indeterminate="isIndeterminate"
                   v-model="checkAll"
                   @change="handleCheckAllChange">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedCities"
                         @change="handleCheckedCitiesChange">
        <div class="checkSty">
          <el-checkbox v-for="item in AllRoles"
                       :label="item.id"
                       :key="item.id">{{item.name}}</el-checkbox>
        </div>

      </el-checkbox-group>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   class="sureBut"
                   @click="bindRole">确认</el-button>
        <el-button class="sureBut"
                   @click="showRoleTuVisible = false">返回</el-button>
      </span>

    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog title="重置密码"
               :visible.sync="showResetPass"
               width="500px">
      <el-form :model="form"
               :rules="passRules"
               ref="password"
               label-width="110px">
        <el-form-item label="输入密码"
                      prop="resetPassword1">
          <el-input v-model="form.resetPassword1"
                    placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码"
                      prop="resetPassword2">
          <el-input v-model="form.resetPassword2"
                    placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary"
                 class="sureBut"
                 @click="resetPasswordSure">确认</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { orgList } from "@/api/system";
import { getList, userAddOrUp, userDel } from "@/api/user";
import {
  getRoleTree,
  bindUserRole,
  getRoleByUserId,
  resetPassword,
  getAllRoles,
  getUserAndRoleList,
  bindRole2User,
} from "@/api/auth";
export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (this.form.resetPassword1 != this.form.resetPassword2) {
        callback(new Error("密码不一致"));
      } else {
        callback();
      }
    };
    return {
      defaultCheckKeys: [],
      showResetPass: false,
      showRoleTuVisible: false,
      type: "新增",
      form: {
        code: "",
      },
      userState: [
        {
          id: 1,
          name: "前端用户",
        },
        {
          id: 2,
          name: "后端管理用户",
        },
        {
          id: 4,
          name: "前后端用户",
        },
      ],
      orgs: [],
      rules: {
        userName: [{ required: true, message: "请输入code", trigger: "blur" }],
        phone: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      passRules: {
        resetPassword1: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        resetPassword2: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validatePass },
        ],
      },
      page: {
        total: 0,
        currentPage: 1,
        page: 1,
        size: 10,
      },
      dialogVisible: false,
      tableData: [],
      currentRoleRow: {},
      RoleTree: [],
      currentPassRow: {},
      AllRoles: [], //权限列表
      checkAll: false,
      checkedCities: [],
      isIndeterminate: true
    };
  },
  mounted () {
    this.getList();
    this.orgList();
    /* getRoleTree().then((res) => {
       this.RoleTree = res.data;
     });*/
    this.getAllRoles();
    // this.getUserAndRoleList1()
  },
  methods: {
    getUserAndRoleList1 () {
      getUserAndRoleList().then((res) => {
        // console.log(res.data)

      });
    },
    getAllRoles () {
      getAllRoles().then((res) => {
        // console.log(res.data)
        this.AllRoles = res.data;
      });
    },
    resetPasswordSure () {
      this.$refs.password.validate((valid) => {
        if (valid) {
          let password = this.$Aes.Encrypt(this.form.resetPassword1);
          console.log(password);
          resetPassword({
            userId: this.currentPassRow.id,
            password,
          }).then((res) => {
            this.showResetPass = false;
          });
        }
      });
    },
    resetPassword (row) {
      this.currentPassRow = row;
      this.showResetPass = true;
      this.form = {};
    },
    showRoleTu (row) {
      this.currentRoleRow = row;

      getRoleByUserId({
        userId: row.id,
      }).then((res) => {
        console.log(res.data, 'huoqu')
        this.checkedCities = res.data
        this.showRoleTuVisible = true;
      });
    },
    bindRole () {
      console.log(this.checkedCities)
      // return
      bindRole2User({
        userId: this.currentRoleRow.id,
        roleId: this.checkedCities,
      }).then((res) => {
        // console.log(res,'ooooo')
        this.showRoleTuVisible = false;
      });
    },
    orgList () {
      orgList().then((res) => {
        this.orgs = res.data.records;
      });
    },
    rebuild (row) {
      sumbitNewStatus(row);
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          delete this.form["typeStr"];
          userAddOrUp(this.form)
            .then((res) => {
              if (res.code == 200) {
                this.dialogVisible = false;
                this.form = {};
                this.getList();
              }
              this.type = "新增";
            })
            .catch((e) => { });
          // alert('submit!');
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleEdit (row) {
      this.type = "编辑";
      this.form = row;
      this.dialogVisible = true;
    },
    handleDel (row) {
      userDel(row.id).then((res) => {
        this.getList();
      });
    },
    getList ({ page, size } = this.page) {
      getList({ page, size }).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
      });
    },
    handleSizeChange (val) {
      this.page.size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.page.page = val;
      this.getList();
    },
    handleCheckAllChange (val) {
      let arr = this.AllRoles.map(item => {
        return item.id
      })
      this.checkedCities = val ? arr : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange (value) {
      this.checkedCities = value
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.AllRoles.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.AllRoles.length;
      console.log(this.checkedCities, 'nnnnn')
    }
  },
};
</script>

<style>
.checkSty {
  display: flex;
  flex-direction: column;
}
</style>

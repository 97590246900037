<template>
  <div class="h100">
    <el-card class="box-card">
      <el-form :inline="true"
               :model="search"
               class="demo-form-inline">
        <el-form-item label="所属模块"
                      prop="moduleId">
          <el-select v-model="search.moduleId"
                     clearable
                     placeholder="请选择模块功能">
            <template v-for="(item, i) in modules">
              <el-option :value="item.id"
                         :label="item.module"
                         :key="i"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="解析方法"
                      prop="moduleId">
          <el-select v-model="search.methodId"
                     clearable
                     placeholder="请选择解析方法">
            <template v-for="(item, i) in methods">
              <el-option :value="item.id"
                         :label="item.name"
                         :key="i"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="组织架构"
                      prop="orgId">
          <el-select v-model="search.orgId"
                     clearable
                     placeholder="请选择组织">
            <template v-for="(item, i) in orgs">
              <el-option :value="item.id"
                         :label="item.name"
                         :key="i"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="条件判断值"
                      prop="ifcondition">
          <el-input v-model="search.ifcondition"
                    clearable
                    placeholder="请输入条件判断值"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="dialogVisible = true;form={status:1};type='新增'">新增</el-button>
        </el-form-item>
      </el-form>
      <el-row class="mb20"> </el-row>
      <el-table :data="tableData"
                border
                style="width: 100%"
                size="mini">
        <el-table-column prop="moduleName"
                         label="功能模块"
                         width="50">
        </el-table-column>
        <el-table-column prop="methodName"
                         label="解析方法"
                         width="150">
        </el-table-column>
        <el-table-column prop="orgName"
                         label="组织机构"
                         width="80">
        </el-table-column>
        <el-table-column prop="ifcondition"
                         label="条件判断值"
                         width="80">
        </el-table-column>
        <el-table-column prop="implement"
                         label="成功执行值">
        </el-table-column>
        <!-- <el-table-column prop="createTime" label="创建时间" width="150"> </el-table-column> -->
        <el-table-column prop="updateTime"
                         label="更新时间"
                         width="150"> </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="100">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)"
                       type="text"
                       size="small">编辑</el-button>
            <el-popconfirm title="这是一段内容确定删除吗？"
                           @confirm="handleDel(scope.row)">
              <el-button slot="reference"
                         type="text"
                         class="ml10"
                         size="small">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tablePage"> -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="page.currentPage"
                     :page-sizes="[10, 200, 300, 400]"
                     :page-size="page.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="page.total">
      </el-pagination>
      <!-- </div> -->
    </el-card>
    <el-dialog :title="type"
               :visible.sync="dialogVisible"
               width="80%">
      <el-form ref="form"
               :model="form"
               :rules="rules"
               label-width="100px">
        <el-form-item label="解析方法"
                      prop="methodId">
          <el-select v-model="form.methodId"
                     clearable
                     placeholder="请选择解析方法">
            <template v-for="(item, i) in methods">
              <el-tooltip :key="i"
                          :content="item.remark"
                          placement="right"
                          effect="dark">
                <el-option :value="item.id"
                           :label="item.name"
                           :key="i"></el-option>
              </el-tooltip>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="所属组织">
          <el-select v-model="form.orgId"
                     clearable
                     placeholder="请选择所属组织">
            <template v-for="(item, i) in orgs">
              <el-option :value="item.id"
                         :label="item.name"
                         :key="i"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="所属模块" prop="moduleId">
    <el-select v-model="form.moduleId" placeholder="请选择模块功能">
      <template v-for="(item,i) in modules">
<el-option :value="item.id" :label="item.module" :key="i"></el-option>

      </template>
      
    </el-select>
  </el-form-item> -->
        <el-form-item label="条件判断值"
                      prop="ifcondition">
          <el-input v-model="form.ifcondition"
                    placeholder="请输入判断值，多个可用“;”(英文状态下)隔开"></el-input>
        </el-form-item>
        <!-- <el-form-item
    v-for="(domain, index) in jsonName"
    :label=" domain"
    :key="index"
  >
    <el-input class="w50 mr20" v-model="form.implement.domain"></el-input>
    <el-button @click.prevent="removeDomain(domain)">删除</el-button>
  </el-form-item> -->
        <el-form-item label="成功执行值"
                      prop="implement">
          <el-input v-model="form.implement"
                    rows="10"
                    type="textarea"
                    placeholder="请输入成功执行值 非json格式多个参数使用'，'隔开对应方法体中的_1,_2,_3..."></el-input>
        </el-form-item>
        <el-form-item label="json格式解析">
          <el-switch v-model="form.status"
                     :active-value="2"
                     :inactive-value="1"
                     active-text="是"
                     inactive-text="否"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submitForm('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看"
               :visible.sync="methodShow"
               @close="codeClode"
               width="50%">
      <div contenteditable="true">{{ methodBody }}</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="methodShow = false">取 消</el-button>
        <el-button type="primary"
                   @click="methodShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  caseList,
  delCaseById,
  caseAddOrUp,
  moduleList,
  methodList,
  orgList
} from "@/api/casecondit";
export default {
  data () {
    let asicc = (rule, value, callback) => {
      if (this.form.status == 2) {
        let text = value.replace("/\n|\r\n|\n\r|\s|a/gi", "");
        if (/^[^{]|[^}]$|:[^"]/.test(text)) {
          if (/:\/\/|:\\|:\{|:\[/.test(text)) {
            callback();
          } else {
            callback(new Error("请输入正确的json格式 {\"xx\":\"xx\"}"));
          }
        } else {
          callback();
        }
      }
      callback();
    };
    return {
      jsonName: [],
      search: {},
      modules: [],
      methods: [],
      orgs: [],
      methodShow: false,
      methodBody: "",
      type: "新增",
      form: {
        status: 1
      },
      rules: {
        ifcondition: [
          { required: true, message: "请输入条件判断值 ", trigger: "blur" },
        ],
        implement: [
          { validator: asicc, trigger: "blur" },
        ],
        methodId: [
          { required: true, message: "请选择方法体", trigger: "blur" },
        ],
      },
      page: {
        total: 0,
        currentPage: 1,
        page: 1,
        size: 10,
      },
      dialogVisible: false,
      tableData: [],
    };
  },
  mounted () {
    this.getList();
    moduleList({ size: 1000 }).then((res) => {
      this.modules = res.data.records;
    });
    methodList({ size: 1000, enableRun: 0 }).then((res) => {
      this.methods = res.data.records;
    });
    orgList({ size: 100 }).then(res => {
      this.orgs = res.data.records;
    })
  },
  methods: {
    codeClode () { },
    handleLook (row) {
      this.methodBody = row.body;
      this.methodShow = true;
    },
    formatNum (row, column, cellValue, index) {
      return cellValue == 1 ? "是" : "否";
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          caseAddOrUp(this.form)
            .then((res) => {
              if (res.code == 200) {
                this.dialogVisible = false;
                this.form = {};
                this.getList();
              }
              this.type = "新增";
            })
            .catch((e) => { });
          // alert('submit!');
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleEdit (row) {
      this.type = "编辑";
      this.form = row;
      this.dialogVisible = true;

    },
    handleDel (row) {
      delCaseById(row.id).then((res) => {
        this.getList();
      });
    },
    handleSearch(){
      this.page.currentPage = 1;
      this.page.page = 1;
      this.getList();
    },
    getList () {
      let data = Object.assign(this.page, this.search);
      caseList(data).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
      });
    },
    handleSizeChange (val) {
      this.page.size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.page.page = val;
      this.getList();
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="h100">
    <el-card class="box-card">
      <el-row class="mb20">
        <el-button type="primary" @click="dialogVisible = true;form={};type='新增'">新增</el-button>
        <el-button class="ml20" type="primary" @click="showRoleTu">角色层级</el-button>
      </el-row>
      <el-table :data="tableData" border style="width: 100%" size="mini">
        <el-table-column prop="name" label="名称" width="100">
        </el-table-column>
        <el-table-column prop="orgName" label="上级" width="100">
            <template slot-scope="scope">
                <el-button @click="addParent(scope.row)" type="text" size="small"
              >添加上级</el-button>
            </template>
        </el-table-column>
        <!-- <el-table-column prop="typeStr" label="下级" width="100">
            <template slot-scope="scope">
                <el-button @click="addchild(scope.row)" type="text" size="small"
              >添加下级</el-button>
            </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="relation(scope.row)" type="text" size="small"
              >关联数据权限</el-button>
            <el-button @click="handleEdit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-popconfirm
  title="这是一段内容确定删除吗？"
  @confirm="handleDel(scope.row)"
>
   <el-button slot="reference" type="text" class="ml10" size="small">删除</el-button>
</el-popconfirm>
           
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tablePage"> -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 200, 300, 400]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
      <!-- </div> -->
        </el-card>
    <el-dialog
      :title="type"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="名称" prop="name" >
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item> 
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')"
          >确 定</el-button
        >
      </span>


    </el-dialog>


    <!-- 角色图 -->
    <el-dialog
      title="角色图"
      :visible.sync="showRoleTuVisible"
      width="80%"
    >
    
    <el-tree
  :data="RoleTree"
  node-key="id"
  default-expand-all

  >
</el-tree>
    
    </el-dialog>

      <!-- 添加父子级 -->
    <el-dialog
      title="添加上级"
      @close="closeParentEvent"
      :visible.sync="showParent"
      width="80%"
    >
    <el-select v-model="parentids" multiple filterable placeholder="请选择">
    <el-option
      v-for="item in parentidsAll"
      :key="item.id"
      :label="item.label"
      :value="item.id">
    </el-option>
  </el-select>
    <el-button type="primary" @click="addparentSure">确认</el-button>
    </el-dialog>

     <!-- 关联权限 -->
    <el-dialog
    top="0"
    custom-class="quanDialog"
    @close="closeRelationEvent"
      title="关联权限"
      :visible.sync="showChild"
      width="1210px"
    >
    <el-transfer
    filterable
    :titles="['未关联权限','已关联权限']"
    :filter-method="filterMethod"
    filter-placeholder="请输入路径开头"
    v-model="relationValue"
    :data="relationData">
    <template slot-scope="{option}">
              <div>
                <span class="left">{{option.value}}</span>
                <span class="right">{{option.label}}</span>
              </div>
            </template>
  </el-transfer>
    <el-button type="primary" class="sureBut" @click="addRelation">确认</el-button>
     <el-button  class="sureBut" @click="showChild=false">返回</el-button>
    </el-dialog>

  </div>
</template>

<script>
import {getAllUrl,getAllRoleById,getRoleTree,getRoleList,roleAdd
,roleUpdate,roleDel,roleAddParent,menuAll,getRoleResourcesById,bindRoleResource} from "@/api/auth";
export default {
  data() {
    return {
        childids:[],
        parentids:[],
        parentidsAll:[],
      type:"新增",
      form:{
        code:''
      },
      rules: {
          name: [
            { required: true, message: '请输入code', trigger: 'blur' },
          ],
          },
      page: {
        total: 0,
        currentPage: 1,
        page:1,
        size:10
      },
      dialogVisible: false,
      showRoleTuVisible:false,
      showParent:false,
      tableData: [],
      RoleTree:[],
      addParentRow:{},
      showChild:false,
      addChildRow:{},
      relationData:[],
      relationValue:[],
      currentRelation:{}
    };
  },
  mounted() {
    this.getList();
    menuAll().then(res=>{
        this.relationData=res.data;
      })
  },
  methods: {
    addRelation(){
      bindRoleResource({
        roleId:this.currentRelation.id,
        resources:this.relationValue.join(",")
      }).then(res=>{
        this.showChild=false
      })
    },
    filterMethod(query, item) {
          return item.value.indexOf(query) > -1;
        },
    relation(row){
      this.currentRelation=row;
      this.showChild=true;
      getRoleResourcesById({
        roleId:row.id,
      }).then(res=>{
        this.relationValue=res.data;
        
      })
    },
      closeParentEvent(){
          this.addParentRow={};
          this.parentids=[];
      },
      closeRelationEvent(){
          this.currentRelation={};
          this.relationValue=[];
      },
      addparentSure(){
          roleAddParent({
              id:this.addParentRow.id,
              pidstr:this.parentids.join(',')
          }).then(()=>{
              this.showParent=false;
          })
      },
      addParent(row){
          this.addParentRow=row;
          this.showParent=true;
          getAllRoleById({id:row.id}).then(res=>{
              this.parentidsAll=res.data.all;
              this.parentids=res.data.parentids;
          })
      },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let method=roleAdd;
            if(this.type=="编辑"){
                method=roleUpdate;
            }
            method(this.form).then(res=>{
              if(res.code==200){
                this.dialogVisible=false;
                this.form={};
                this.getList();
              }
              this.type = "新增";
            }).catch(e=>{
              
            })
            // alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    handleEdit(row) {
      this.type = "编辑";
      this.form = row;
      this.dialogVisible=true;
    },
    showRoleTu(row){
        getRoleTree().then(res=>{
            this.RoleTree=res.data;
        })
        this.showRoleTuVisible=true;
    },
    handleDel(row) {
      roleDel(row.id).then(res=>{
        this.getList();
      })
    },
    getList({page,size}=this.page) {
      getRoleList({page,size}).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
      });
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
  },
};
</script>

<style>
.el-transfer-panel{
  width:500px;
}
.el-transfer{
  height: 600px;
}
.el-transfer-panel__body{
  height: 550px;
}
.el-transfer-panel__list.is-filterable{
  height: 530px;
}
.sureBut{
  margin-left: 550px;
}
.quanDialog{
  height: 100%;
}
</style>
<template>
  <div class="fileBox">
      <div class="search" v-if="showSearch">
          <el-input  ref="search" clearable v-model="search" placeholder="搜索的文件夹名称"></el-input>
      </div>
      <el-breadcrumb >
        <el-breadcrumb-item @click.native="breadDir(index)" v-for="(item,index) in setRootPath" :key="index">{{!item?"根目录":item}}</el-breadcrumb-item>
        </el-breadcrumb>
        <template v-for="item in list">
<File v-bind="item" @openDir="openDir" @showImg="showImg" @delFile="delFile" @reName="reName" @zip="zip" @unzip="unzip"></File>
        </template>
        <el-dropdown style="float:left">
            
   <el-upload
            
         ref="upload"
         class="uploadBox"
        accept=".zip"
        :on-success="success"
        :auto-upload="false"
  with-credentials
  :on-change="fileChange"
  :show-file-list="true"
  :data="{type:this.fileType,path:this.rootPath}"
  :limit="1"
  :action="base_url+'zhdt/uploadFile'"
  list-type="text"
  :on-preview="handlePictureCardPreview"
  :on-remove="handleRemove">
  <i class="el-icon-plus"></i>
  <div slot="tip" class="el-upload__tip">
      </div>
</el-upload>

  <el-dropdown-menu slot="dropdown">
      <el-tooltip class="item" effect="dark" content="只处理zip文件，不超过100MB" placement="top">
    <el-dropdown-item @click.native="uploadFn('')">普通上传</el-dropdown-item>
      </el-tooltip>
    <el-tooltip class="item" effect="dark" content="只处理zip文件，不超过100MB，重命名图片需以0_开头" placement="top">
    <el-dropdown-item @click.native="uploadFn('screen')">上传后重命名zip</el-dropdown-item>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="只处理zip文件，不超过100MB" placement="top">
    <el-dropdown-item @click.native="uploadFn('unzip')">上传后解压缩</el-dropdown-item>
      </el-tooltip>
  </el-dropdown-menu>
</el-dropdown>

<el-dialog
top="20px"
  title="大屏图片展示"
  width="1240px"
  :visible.sync="dialogVisible">
  <div class="showBox flex flex_col flex_just_left flex_wrap">
       <img v-for="(item,index) in 120" :key="index" class="flex" :src="base_url+'getFile/'+getImgName(item)+'.png'" />
  </div>
 

</el-dialog>
  </div>
</template>

<script>
import {getList,delFile,reNameFile,zip,unzip} from "@/api/file"
import File from "@/components/File"
import {base_url} from "@/utils/request"
export default {

    components:{File},
    data(){
        return{
            showSearch:false,
            search:'',
            dialogImageUrl: '',
        dialogVisible: false,
        listorgin:[],
            list:[],
            rootPath:"",
            separator:'',
            showPath:'',
            base_url:base_url,
            fileList:[],
            fileType:"",
            fileSelect:false,
            isUploadFnSelect:false
        }
    },
    computed:{
        setRootPath(){
            return this.rootPath.split(this.separator);
        },
    },
    methods:{
        uploadFn(type){
            this.fileType=type;
            if(this.fileSelect){
                console.log(' submit')
                this.$nextTick(()=>{
this.$refs.upload.submit();
                })
                return
            }
            this.isUploadFnSelect=true
            this.$refs.upload.$el.querySelectorAll('input')[0].click();
        },
        fileChange(e){
            if(this.isUploadFnSelect){
  this.$nextTick(()=>{
this.$refs.upload.submit();
                })
            }else{
this.fileSelect=true
            }
              
        },
        zip(data){
            zip({path:data.path}).then(res=>{
                this.getList(this.rootPath);
            })
        },
        unzip(data){
unzip({path:data.path}).then(res=>{
                this.getList(this.rootPath);
            })
        },
        delFile(data){
            delFile(data.path).then(res=>{
                this.getList(this.rootPath);
            })
        },
        getImgName(item){
            let index = (item<10)?("00"+item):(item<100)?("0"+item):item;
            return this.showPath + index;
        },
        reName({path,value}){
            reNameFile({path,value}).then(res=>{
                this.getList(this.rootPath);
            })
        },
        showImg(path){
            this.showPath=path+this.separator;
            this.dialogVisible=true
        },
        openDir(path){
            this.getList(path);
        },
        success(){
            this.$nextTick(()=>{
                this.fileSelect=false;
                this.isUploadFnSelect=false;
            })
            this.$refs['upload'].clearFiles();
            this.getList(this.rootPath);
        },
        breadDir(index){
            let root = ""
            for(let i=0;i<this.setRootPath.length;i++){
                if(i<=index){
                    root +=this.separator+this.setRootPath[i];
                }
            }
            this.getList(root);
        },
        getList(path){
            getList({prentpath:path}).then(res=>{
                this.list=res.data.list;
                this.listorgin=JSON.parse(JSON.stringify(res.data.list))
                this.rootPath = res.data.path;
                this.separator = res.data.separator;
            });
        },
        handleRemove(file, fileList) {
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    },
    mounted(){
        this.getList("");
        const self=this;
        let time=new Date().getTime()
  document.onkeyup = function(e){
    let evn = e || event ;
    let key = evn.keyCode || evn.which || evn.charCode ;
    if(key === 70){
      let notime= new Date().getTime();
        if(notime-time <300){
            self.showSearch=!self.showSearch;
            if(self.showSearch){
                self.$nextTick(()=>{
                    self.$refs.search.focus();
// console.log([self.$refs['search']])
                })
                
                
            }
        }
        time=new Date().getTime()
    }
    if(key===13){
        if(!!self.search){
            let arr=[]
            for(let i=0;i<self.listorgin.length;i++){
                if(self.listorgin[i].name==self.search){
                    arr.push(self.listorgin[i])
                }
            }
            self.list=arr;
        }else if(!!self.showSearch&&!self.search){
            self.list = JSON.parse(JSON.stringify(self.listorgin))
        }
    }
    
    return ""
  }
    }

}
</script>
<style lang="css">
.fileBox /deep/ .el-upload{
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin: 200px;
}
.fileBox /deep/ .el-breadcrumb__inner{
    cursor: pointer!important;
}
.fileBox /deep/ .el-breadcrumb__inner:hover{
    color: blue!important;
}
.uploadBox /deep/ .el-upload{
    margin-bottom: 0px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 80px;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
    vertical-align: top
}
</style>
<style lang="scss" scoped>

.fileBox{
    overflow: auto;
    height: 100%;
    position: relative;
    .search{
    position: fixed;
    right: 125px;
    top:125px;
    width:200px;
    height: 50px;
    z-index: 200;
    padding:10px;
    background-color: darkslategrey;
}
}
.showBox{
    width: 1200px;
    height: 600px;
    img{
        width: 120px;
        height: 50px;
    }
}
</style>